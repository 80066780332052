export const API_ENDPOINTS = {
  LOGIN: () => "user/login",
  SIGNUP: () => `user/register`,
  GET_CUSTOMER: (id) => (id ? `customer/${id}` : "/customer/"),
  GET_DASHBOARD_DATA: (role, id) => `dashboard/${role}/${id}`,
  GET_REQUESTS_BY_CUSTOMER_ID: (id) => `request/customer/${id}`,
  GET_ALL_REQUESTS: () => "request/all",
  UPDATE_CUSTOMER: (id) => `customer/${id}`,
  UPDATE_USER: (id) => `user/update/${id}`,
  GET_TEMPLATES: (id) => `customer/templates/${id}`,
  SEND_BROADCAST_MESSAGE: (id) => `message/send-broadcast/${id}`,
  EMAIL_VERIFICATION: () => `user/email-verification/`,

  // WhatsApp Business API
  GET_BUSINESS_TOKEN: () => `main/embedded/get-and-update-business-token`,
};
